import { Link } from "react-router-dom";
const Navbar = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const sizeMedia = window.matchMedia("(max-width: 640px)").matches;
  return (
    <div className="navbar bg-base-100 font-medium relative z-10 text-lg xl:text-xl lg:text-xl md:text-xl sm:text-lg">
      <div className="flex-1">
        <a
          href="/#top"
          className="btn btn-ghost font-medium"
          onClick={scrollToTop}
        >
          Paulo Alves
        </a>
      </div>
      <div className="flex-none">
        <ul className="menu menu-horizontal px-1">
          {!sizeMedia && (
            <>
              <li onClick={scrollToTop}>
                <Link to={`/`}>Home</Link>
              </li>
              <li onClick={scrollToTop}>
                <Link to={`/blog`}>Blog</Link>
              </li>
              <li onClick={scrollToTop}>
                <Link to={`/impossible-list`}>Impossible List</Link>
              </li>
            </>
          )}

          {sizeMedia && (
            <>
              <li>
                <details>
                  <summary></summary>
                  <ul className="bg-base-100 rounded-t-none p-2 z-10 -left-full">
                    <li onClick={scrollToTop}>
                      <Link to={`/`}>Home</Link>
                    </li>
                    <li onClick={scrollToTop}>
                      <Link to={`/blog`}>Blog</Link>
                    </li>
                    <li onClick={scrollToTop}>
                      <Link to={`/impossible-list`}>Impossible List</Link>
                    </li>
                    <li onClick={scrollToTop}>
                      <Link to={`/cv`}>CV</Link>
                    </li>
                  </ul>
                </details>
              </li>
              <li></li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
